<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>评论列表</a-breadcrumb-item>
            </a-breadcrumb>
            <div class="toolsbar">
                <div style="float: left;margin-left: 20px;margin-top: 10px;">
<!--                    <a-button type="primary" @click="addannouncement()" style="margin-right: 20px">添加新区块</a-button>-->
                                        <a-button type="primary" @click="upindex()">保存</a-button>
                    <a-button :loading="loading" @click="start" style="margin-left: 20px" type="primary">
                        刷新
                    </a-button>
                </div>

            </div>
            <div style="padding:24px; background: #fff;minHeight: 360px">
<!--                {{indexdata}}-->
<!--                {{fileList}}-->
                <a-list
                        class="comment-list"
                        item-layout="horizontal"
                        :data-source="indexdata"
                >
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-row type="flex" STYLE="min-height: 400px;min-width: 75%">
                            <a-col  :flex="14">
                                <a-form-item
                                        label="背景图"
                                        :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }"

                                >
                                    <!-- 点击上传拍照/上传图片 -->
                                    <img
                                            v-show="indexdata[index].bgurl !==''"
                                            :src="server_url+indexdata[index].bgurl"
                                            style="padding: 3px; border: 1px solid #999"
                                    /><br v-show="indexdata[index].bgurl !==''"/>
                                    <a-button type="primary" @click="delimgae(index)" v-show="indexdata[index].bgurl !==''">剔除</a-button>
                                    <a-upload
                                            :action="server_url+'api/admin/tools/saveFile?uid='+uid+'&token='+token+'&save_path=/images/index'"
                                            list-type="picture-card"
                                            @change="handleChange($event,index)"
                                    >
                                        <div v-if="indexdata[index].bgurl==''">
                                            <a-icon type="plus"/>
                                            <div class="ant-upload-text">
                                                点击上传
                                            </div>
                                        </div>
                                    </a-upload>

                                    <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
                                        <img :src="previewImage" alt="" style="width: 100%"/>
                                    </a-modal>
                                    <!--                        <a-input id="image_groups" placeholder="请输入文章名字"/>-->
                                </a-form-item>
                                <a-form-item label="标题:" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
                                    <a-input v-model="item.Name"/>
                                </a-form-item>
                                <a-form-item label="跳转作品:" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
                                    <a-input v-model="item.jumpurl"/>
                                </a-form-item>


                                <a-form-item label="介绍内容:" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
                                    <a-textarea
                                            v-model="item.textarea"
                                            placeholder="请输入公告内容"
                                            :auto-Size="{ minRows: 2, maxRows: 3 }"
                                            style="width: 100%;min-height: 50px;"
                                    />
                                    <!--                                    <quill-editor-->
                                    <!--                                            STYLE="height: 300PX;"-->
                                    <!--                                            :content="item.content"-->
                                    <!--                                            :options="editorOption"-->
                                    <!--                                            @change="onEditorChange($event,index)"-->
                                    <!--                                    />-->
                                </a-form-item>
                            </a-col>
                        </a-row>


                    </a-list-item>
                </a-list>
            </div>
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>
    </a-layout>

</template>

<script>
    import Storage from "../common/storage";
    import Config from "../config";
    // import moment from "moment";
    // import * as Util from "../common/util";


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    export default {
        name: "Dashboard",
        components: {},
        data() {
            return {
                labelCol: {span: 2},
                uid: Storage.uid,
                token: Storage.token,
                server_url: Config.data.server_url,
                wrapperCol: {span: 8},
                indexdata: [],
                previewVisible: false,
                previewImage: '',
                pagination: {
                    pageSize: 10
                },
                search_text: "",
                loading: false,
                fileList: [],
            };
        },
        created() {
            // 获取第一页内容
            this.getindex();
        },
        methods: {
            start() {
                this.loading = true;
                this.indexdata = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.getindex(1, this.pagination.pageSize);
            },

            async addannouncement() {

                    this.indexdata.push(
                        {
                            "Name": "",
                            "bgurl": "",
                            "jumpurl": "",
                            "textarea": "",
                        }
                    )
            },
            async handlePreview(file) {

                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleChange(info,id) {

                // this.imglist220=[];
                // console.log(info);
                // console.log(info);
                console.log(id);
                this.fileList = info.fileList;
                for (var i = 0; i < this.fileList.length; i++) {
                    if(info.file.status == "done" || info.file.status == "removed"){
                        console.log(this.fileList[i].response.data.src);
                        this.indexdata[id].bgurl = this.fileList[i].response.data.src;
                        // this.imglist800 = this.fileList[i].response.data[0];
                        // this.imglist800.push(this.iteminfo.fileList[i].response.data[0]);
                        // this.imglist220.push(this.iteminfo.fileList[i].response.data[1]);
                    }
                    // console.log(info.fileList[i]);
                    // console.log(info.fileList[i].response.data);
                }


            },
            handleCancel() {
                this.previewVisible = false;
            },
            delimgae(id) {
                console.log(id)
                console.log(this.indexdata[id].bgurl)
                this.indexdata[id].bgurl = "";
            },
            async getindex() {
                this.loading = true;
                // this.indexdata = [];

                let now = Date.now();
                let result = await this.$get("/api/admin/indexContent/get", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        t: now,
                    }
                });

                if (result.status === true) {
                    this.indexdata = JSON.parse(result.data.content);
                    for (var k = 0; k < this.indexdata.length; k++) {
                        let newfileList2 = {
                            uid: k,
                            name: '',
                            status: 'done',
                            url: this.server_url + this.indexdata[k].bgurl,
                            response: {
                                data: {
                                    src:this.indexdata[k].bgurl
                                }
                            },
                        }
                        this.fileList.push(newfileList2)
                        console.log("fileList_collections",  this.fileList)
                    }
                    this.loading = false;
                }

                this.loading = false;
            },
            async upindex() {
                this.loading = true;
                let now = Date.now();
                let result = await this.$post("/api/admin/indexContent/update", {

                        token: Storage.token,
                        uid: Storage.uid,
                        save_value:this.indexdata,
                        t: now,
                });

                if (result.status === true) {
                    console.log("保存成功")
                    this.loading = false;
                }

                this.loading = false;
            },


        }
    };
</script>
<style scoped>
    .editable-row-operations a {
        margin-right: 8px;
    }

    #components-layout-demo-custom-trigger .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    #components-layout-demo-custom-trigger .trigger:hover {
        color: #1890ff;
    }

    #components-layout-demo-custom-trigger .logo {
        height: 32px;
        background: rgba(255, 255, 255, 0.2);
        margin: 16px;
    }

    .serach_input {
        width: 25vw;
        float: right;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: right;
        margin-right: 25px;
    }

    .pages {
        float: right;
        margin-top: 10px;
    }

    .toolsbar {
        display: block;
        width: 100%;
        height: 42px;
        background: #fff
    }

    .home {
        display: flex;
        width: 1154px !important;
        overflow: hidden;
    }

    .home_left {
        display: flex;
        width: 781px;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    .home_left div {
        height: 286px;
        overflow: hidden
    }
    .home_left div:nth-child(5) {
        display: none;
    }

    .home_right {
        display: flex;
        margin: 0;
        padding: 0;
    }

    .index1_0 {
        width: 180px;
        height: 286px;
        cursor: pointer !important;
    }

    .index1_0:before {
        content: "1";
        color: red;
        font-size: 18px;
        margin-right: 10px;
    }

    .index1_1:before {
        content: "2";
        color: red;
        font-size: 18px;
        margin-right: 10px;
    }

    .index1_2:before {
        content: "3";
        color: red;
        font-size: 18px;
        margin-right: 10px;
    }

    .index1_3:before {
        content: "4";
        color: red;
        font-size: 18px;
        margin-right: 10px;
    }

    .index1_4:before {
        content: "5";
        color: red;
        font-size: 18px;
        margin-right: 10px;
    }

    .index1_1 {
        width: 601px;
        height: 286px;

        cursor: pointer !important;
    }

    .index1_2{
        width: 601px;
        height: 286px;

        cursor: pointer !important;
    }

    .index1_3 {
        width: 180px;
        height: 286px;

        cursor: pointer !important;
    }

    .index1_4 {
        width: 372px;
        height: 575px;

        cursor: pointer !important;
    }

    .backimg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: relative;
        border: 1px dotted #ee5555;
        background-color: #ccc;
    }

    .moreinfo {
        position: absolute;
        color: white;
        font-size: 12px;
        left: 10px;
        bottom: 10px;
        max-height: 150px;
    }

    .moreinfo h1 {
        font-size: 18px !important;
        color: white !important;
    }
</style>
